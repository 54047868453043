import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/bituminous-mixtures/mixtures/formulation-studies/form-schema';
import { FormulationStudiesRepository } from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { LotInterventionsRepository }   from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, FormulationStudiesRepository, FormSchema, LotInterventionsRepository)
export class CreateFormulationStudy extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'bituminous-mixtures.mixtures.formulation-studies.creation-form';

    alert          = {};
    warning        = {};
    propertiesInfo = {};
    model          = {};
    schema         = {};

    create = true;

    properties = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, formSchema, lotInterventionsRepository) {
        super(appContainer);

        this.repository                 = repository;
        this.formSchema                 = formSchema;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.formulation-studies.index') && super.can([
            'bituminous-mixtures.mixtures.formulation-studies.manage',
            'bituminous-mixtures.mixtures.formulation-studies.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        // Initialize the model
        this.initializeModel();

        // Fetch the next number and selected lot information
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Assign base lot information
        this.assignBaseLotInformation(selectedLotInformation).then(() => {
            this.schema                      = this.formSchema.schema(this, false, true);
            this.additionalInformationSchema = this.formSchema.additionalInformationSchema(this);
            this.lotInfoSchema               = this.formSchema.lotInfoSchema(this);
            this.globalSchema                = this.formSchema.globalSchema(this);
        });
    }

    /**
     * Initializes the model and sets the initial state
     */
    initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param selectedLotInformation
     */
    async assignBaseLotInformation(selectedLotInformation) {
        const baseInfo = {
            // For the model
            team_id:             selectedLotInformation.team_id,
            lot_intervention_id: selectedLotInformation.lot_intervention_id,

            // For display purposes
            entity_team_name:       selectedLotInformation.entity_name + ' (' + selectedLotInformation.team_name + ')',
            highway_name:           selectedLotInformation.highway_name,
            concession_name:        selectedLotInformation.concession_name,
            intervention_type_name: selectedLotInformation.lot_intervention_type_name,
            lot_intervention_name:  selectedLotInformation.lot_intervention_name,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'bituminous-mixtures.mixtures.formulation-studies.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}
