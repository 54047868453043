import { EventAggregator }           from 'aurelia-event-aggregator';
import { inject }                    from 'aurelia-framework';
import { I18N }                      from 'aurelia-i18n';
import { CompositionDataRepository } from 'modules/management/bituminous-mixtures/composition-data/services/repository';
import { BaseFilterFormSchema }      from 'resources/classes/base-filter-form-schema';
import { SessionStorage }            from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, CompositionDataRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param compositionDataRepository
     */
    constructor(storage, eventAggregator, i18n, compositionDataRepository) {
        super(storage, eventAggregator, i18n);

        this.compositionDataRepository = compositionDataRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.composition_data_id = {
            type:         'select2',
            key:          'composition_data_id',
            label:        'form.field.study-type',
            required:     false,
            size:         12,
            remoteSource: () => this.compositionDataRepository.all(),
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.composition_data_id],
            [this.buttons],
        ];
    }
}
