import { inject }                          from 'aurelia-framework';
import { FormulationStudyCompositionData } from 'modules/bituminous-mixtures/models/formulation-study-composition-data';
import { CompositionDataRepository }       from 'modules/management/bituminous-mixtures/composition-data/services/repository';
import { CompositionField }                from 'modules/management/models/composition-field';

@inject(CompositionDataRepository)
export class FormulationStudyCompositionDataFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param compositionDataRepository
     */
    constructor(compositionDataRepository) {
        this.compositionDataRepository = compositionDataRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {FormulationStudyCompositionData}
     */
    model() {
        let model = new FormulationStudyCompositionData();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.composition_data_id = {
            type:         'select2',
            key:          'composition_data_id',
            label:        'form.field.study-type',
            size:         viewModel.model.composition_data_id === null ? 12 : 6,
            remoteSource: () => this.compositionDataRepository.all(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                async (newValue, oldValue) => {
                    this.composition_value.hidden = !newValue;

                    if (newValue !== oldValue) {
                        viewModel.schema[0].splice(2);
                        this.composition_data_id.size = 12;
                    }

                    if (newValue) {
                        this.composition_value.label = this.composition_data_id.instance.selectedRecord().name;

                        this.compositionDataRepository.fields(newValue).then((response) => {
                            response.map((field) => {
                                this.composition_data_id.size = 6;

                                viewModel.schema[0].push({
                                    type:       CompositionField.getFormFieldType(field.id),
                                    key:        field.field_name,
                                    label:      field.name,
                                    size:       6,
                                    required:   false,
                                    attributes: {
                                        readonly: readonly,
                                    },
                                });

                                viewModel.model[field.field_name]        = null;
                                viewModel.initialModel[field.field_name] = null;
                            });
                        });
                    }
                },
            ],
        };

        this.composition_value = {
            type:       'text',
            key:        'composition_value',
            label:      'form.field.composition-value',
            size:       6,
            hidden:     viewModel.model.composition_data_id === null,
            attributes: {
                readonly: readonly,
            },
        }

        return [
            [this.composition_data_id, this.composition_value],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
